import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IUser } from '@interfaces/user.interfaces';
import { Iemail } from '@interfaces/email.interfaces';
import { IToken, IResetPassword } from '@interfaces/password.interfaces';
import { LocalStorageService } from '@services/localStorage/local-storage.service';
import { IPermissionsObject } from '@interfaces/permissions.interfaces';
import { SocketService } from '@services/socket/socket.service';
import { SnackBarControlService } from '@services/snackBarControl/snack-bar-control.service';
import {
  AuthLoginResponse,
  LevelsAvailable,
  ResponseSendCode,
  Success2FACodeValidationResponse,
  TokenResponse,
  ValidateAccountResponse,
  ValidationMethod,
} from '@interfaces/auth.interface';
import { SocialUser } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly _user = new BehaviorSubject<IUser['data'] | null>(null);
  private readonly loggedIn = new BehaviorSubject<boolean>(false);
  private readonly userId = new BehaviorSubject<number | null>(null);
  private readonly isRedirectingBS = new BehaviorSubject<boolean>(true);
  public isRedirecting$ = this.isRedirectingBS.asObservable();
  public flag: boolean = false;
  public refreshTokenSubject: BehaviorSubject<TokenResponse | null> = new BehaviorSubject<TokenResponse | null>(null);
  constructor(
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly socketService: SocketService,
    private readonly snackbarControlService: SnackBarControlService
  ) {
    this.loadUser();
    this.refreshTokenSubject.subscribe((tokenData: TokenResponse | null) => {
      if (tokenData) {
        this.apiService.setHeader('Authorization', `Bearer ${tokenData.token}`);
        this.localStorageService.setToken(tokenData.token);
      }
    });
  }

  //Solicitud cambio de contraseña
  forgotPassword(email: string): Observable<Iemail> {
    return this.apiService.post<Iemail>('/password/email', { email });
  }

  //Validacion token
  validateToken(email: string | null, token: string | null): Observable<IToken> {
    return this.apiService.post<IToken>('/password/validate', { email, token });
  }

  //Cambio de contraseña
  resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Observable<IResetPassword> {
    return this.apiService.post<IResetPassword>('/password/reset', {
      email,
      token,
      password,
      password_confirmation,
    });
  }

  public login(
    email: string,
    password: string
  ): Observable<AuthLoginResponse<ValidateAccountResponse | LevelsAvailable | Success2FACodeValidationResponse>> {
    const body = { email, password };
    return this.apiService.post<
      AuthLoginResponse<ValidateAccountResponse | LevelsAvailable | Success2FACodeValidationResponse>
    >(`/auth/login`, body);
  }

  send2faCode(token: string, validationMethod: string): Observable<AuthLoginResponse<ResponseSendCode | string>> {
    return this.apiService.post<AuthLoginResponse<ResponseSendCode | string>>('/auth/send', {
      token,
      validationMethod,
    });
  }

  sendValidationToDuo(token: string): Observable<AuthLoginResponse<Success2FACodeValidationResponse>> {
    return this.apiService.post<AuthLoginResponse<Success2FACodeValidationResponse>>('/auth/send', {
      token,
      validationMethod: ValidationMethod.DUO,
    });
  }

  confirm2faCode(credentials: { code: string; token: string }): Observable<IUser['data']> {
    return this.apiService.post<AuthLoginResponse<Success2FACodeValidationResponse>>('/auth/confirm', credentials).pipe(
      map(res => {
        const { body } = res;
        return this.saveLoginData(body);
      })
    );
  }

  public sendAccountValidation(email: string): Observable<AuthLoginResponse<string>> {
    return this.apiService.post<AuthLoginResponse<string>>(`/auth/validate-account`, { email });
  }

  saveLoginData(loginData: Success2FACodeValidationResponse): IUser['data'] {
    const { user, token, refreshToken } = loginData;
    this.setTokenAndUserData(token, refreshToken, user);
    this.setPermissions(user.permissions);
    this._user.next(user);
    this.loggedIn.next(true);
    this.userId.next(user.id);
    this.socketService.reconnectSocket(user.id.toString());
    this.updateFlag(true);
    return user;
  }

  loadUser(): void {
    const token = this.localStorageService.getToken();
    const user = this.localStorageService.getUserData();

    if (token && user) {
      this.apiService.setHeader('Authorization', `Bearer ${token}`);
      this._user.next(user);
      this.loggedIn.next(true);
      this.userId.next(user.id);
    }
  }

  refresh() {
    this.apiService.setHeader('Authorization', `Bearer ${this.localStorageService.getRefreshToken()}`);
    return this.apiService.post<AuthLoginResponse<TokenResponse>>('/auth/refresh').pipe(
      map(res => {
        const {
          body: { token, refreshToken },
        } = res;
        this.localStorageService.setToken(token);
        this.localStorageService.setRefreshToken(refreshToken);
        this.apiService.setHeader('Authorization', `Bearer ${token}`);
      })
    );
  }

  logout() {
    this.apiService.setHeader('Authorization', `Bearer ${this.localStorageService.getRefreshToken()}`);
    return this.apiService.post('/auth/logout').pipe(
      map(() => {
        this.apiService.deleteHeader('Authorization');
        this._user.next(null);
        localStorage.clear();
        this.loggedIn.next(false);
        this.userId.next(null);
        this.socketService.disconnectSocket();
      })
    );
  }

  logoutLocal() {
    this.apiService.deleteHeader('Authorization');
    this._user.next(null);
    localStorage.clear();
    this.loggedIn.next(false);
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  getCurrentUserId(): number | null {
    return this.userId.getValue();
  }

  private setTokenAndUserData(token: string, refreshToken: string, user: IUser['data']): void {
    user.id_t6 = user.id_t6 ? user.id_t6.toUpperCase() : '';
    this.localStorageService.setToken(token);
    this.localStorageService.setRefreshToken(refreshToken);
    this.localStorageService.setUserData(user);
    this.apiService.setHeader('Authorization', `Bearer ${token}`);
  }

  private setPermissions(data: IPermissionsObject[]) {
    const arrayPermissions = data.map(x => {
      return x.name;
    });
    this.localStorageService.setPermissions(arrayPermissions);
  }

  updateFlag(value: boolean) {
    this.isRedirectingBS.next(value);
    this.snackbarControlService.setShowSnackbar(value);
  }

  public socialLogIn(user: Partial<SocialUser>): Observable<Success2FACodeValidationResponse> {
    return this.apiService.post<Success2FACodeValidationResponse>(`/auth/social-login`, user);
  }
}
