export const environment = {
  API_URL: 'https://comprasdev.agd.com.ar/api/compras',
  ASSETS_URL: './assets/',
  SITEKEY: '6Lc6PccpAAAAADkFQhgH9LfyaR4AffivC_VTdCKa',
  NODE_JS_URL: 'https://comprasdev.agd.com.ar',
  RECAPTCHA_ENABLE: true,
  NOINDEX: true,
  GOOGLE_CLIENT_ID: '319550907028-n1nl1h5egljkaoof6gt4g8hof1eivcec.apps.googleusercontent.com',
  VIDEO_INTRO_AGD: 'https://www.agd.com.ar/sites/agd/files/intro-agd.mp4',
};
