import { Component } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { IconsService } from '@services/icons/icons.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { NewVesionCheckerService } from '@services/newVersionChecker/new-vesion-checker.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly iconService: IconsService,
    private readonly metaService: Meta,
    private readonly newVersionCheckerService: NewVesionCheckerService
  ) {
    this.setMetaTags();
    this.newVersionCheckerService.checkForUpdate();
    this.newVersionCheckerService.checkUpdate();
  }

  setMetaTags() {
    if (environment.NOINDEX) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }
}
